import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
var Error = (function (_super) {
    __extends(Error, _super);
    function Error() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Error = __decorate([
        Component
    ], Error);
    return Error;
}(Vue));
export default Error;
